import { useState, useEffect, useMemo, useRef } from 'react'
import { Button, Form, Input, Message } from '@nbit/arco'
import { useRequest } from 'ahooks'
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import UserPopUp from '@/features/user/components/popup'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import UserCountDown from '@/features/user/components/count-down'
import FullScreenSpin from '@/features/user/components/full-screen-loading'
import {
  UserVerifyTypeEnum,
  UserValidateMethodEnum,
  UserEnabledStateTypeEnum,
  UserSendValidateCodeBusinessTypeEnum,
  GetExpirationCodeEnum,
} from '@/constants/user'
import {
  postMemberSafeVerifyEmailSend,
  postMemberSafeVerifyPhoneSend,
  postMemberUniversalSecurityVerification,
  postMemberQueryWorkOrderStatus,
  getMemberBaseSafaVerifyBaseInfo,
} from '@/apis/user'
import { MemberUniversalSecurityVerificationRep, MemberBaseSafaVerifyAccountResp } from '@/typings/user'
import { UserInformationDesensitization } from '@/features/user/utils/common'
import { SafetyVerificationRules } from '@/features/user/utils/validate'
import { useUserStore } from '@/store/user'
import { usePageContext } from '@/hooks/use-page-context'
import Icon from '@/components/icon'
import { getSafetyicationPath } from '@/helper/route'
import { useCustomerServiceLink } from '@/hooks/features/customer-service/index'
import styles from './index.module.css'

const FormItem = Form.Item
const expirationCode = [GetExpirationCodeEnum.expirationCode]

interface UniversalSecurityVerificationProps {
  /** 是否显示弹窗 */
  isShow: boolean
  /** 业务类型 */
  businessType?: number
  /** 关闭弹窗 */
  onClose?(close: boolean): void
  /** 验证通过函数 */
  onSuccess(isTrue: boolean, option?: { [key: string]: string | number }): void
  /** 验证失败函数 */
  onError?(): void
}

function UserSafetyVerification(props: UniversalSecurityVerificationProps) {
  const { isShow, onClose, onSuccess, onError, businessType } = props
  const [verifyMethod, setVerifyMethod] = useState<string>(UserValidateMethodEnum.email)
  const [workOrderPopUp, setWorkOrderPopUp] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [spinShow, setSpinShow] = useState<boolean>(false)
  const isEmailSend = useRef<boolean>(false)
  const isPhoneSend = useRef<boolean>(false)
  const [passwordShow, setPasswordShow] = useState<boolean>(false)
  const customerServiceLinks = useCustomerServiceLink()
  const { userInfo } = useUserStore()
  const [memberUserInfo, setMemberUserInfo] = useState<MemberBaseSafaVerifyAccountResp>()

  // const [disabled, setDisabled] = useState<boolean>(true)

  const store = useUserStore()
  const [form] = Form.useForm()
  const pageContext = usePageContext()

  const info = userInfo

  const rules = SafetyVerificationRules(isEmailSend, isPhoneSend)

  const getVerifyByAccoun = async () => {
    const res = await getMemberBaseSafaVerifyBaseInfo({})
    if (res?.isOk) {
      setMemberUserInfo(res?.data)
      const data = res?.data
      const verifyInputShow =
        data?.isEmailEnable && data?.verifyInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable
          ? UserValidateMethodEnum.email
          : data?.isMobileEnable && data?.verifyInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable
          ? UserValidateMethodEnum.phone
          : data?.isGoogleEnable && data?.verifyInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable
          ? UserValidateMethodEnum.validator
          : UserValidateMethodEnum.pwd
      setVerifyMethod(verifyInputShow)
    }
  }

  useEffect(() => {
    getVerifyByAccoun()
  }, [])

  const handleToSecurityItem = async () => {
    onClose && onClose(false)
    const options = {
      type: memberUserInfo?.verifyInfo?.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone,
      account: memberUserInfo?.verifyInfo?.email || memberUserInfo?.verifyInfo?.mobileNumber || '',
      mobileCountryCode: memberUserInfo?.verifyInfo?.mobileNumber ? memberUserInfo?.verifyInfo?.mobileCountryCd : '',
    }
    const res = await postMemberQueryWorkOrderStatus(options)
    res.isOk && res.data?.isBeginManualVerifyProcess ? setWorkOrderPopUp(true) : link(getSafetyicationPath())
  }

  const switchVerifyMethod = (type: string) => {
    setVerifyMethod(type)
    form.clearFields()
  }

  const handleSendEmailValidateCode = async () => {
    const res = await postMemberSafeVerifyEmailSend({
      type: businessType || UserSendValidateCodeBusinessTypeEnum.withdraw,
      email: userInfo?.email as string,
    })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isEmailSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleSendPhoneValidateCode = async () => {
    const res = await postMemberSafeVerifyPhoneSend({
      type: businessType || UserSendValidateCodeBusinessTypeEnum.withdraw,
      mobileCountryCode: userInfo?.mobileCountryCd as string,
      mobile: userInfo?.mobileNumber as string,
    })

    const isTrue = (res.isOk && res.data?.isSuccess) || false
    if (isTrue) {
      isPhoneSend.current = true
      Message.success(t`user.field.reuse_38`)
    }
    return isTrue
  }

  const handleValidateCode = async values => {
    const options: MemberUniversalSecurityVerificationRep = {
      operateType: businessType || UserSendValidateCodeBusinessTypeEnum.withdraw,
      account:
        info.email ||
        info.mobile ||
        memberUserInfo?.verifyInfo?.email ||
        memberUserInfo?.verifyInfo?.mobileNumber ||
        memberUserInfo?.verifyInfo?.uid,
      mobileCountryCode: info.mobileCountryCode || memberUserInfo?.verifyInfo?.mobileCountryCd,
    }
    switch (verifyMethod) {
      case UserValidateMethodEnum.email:
        options.emailVerifyCode = values?.verifyCode
        break
      case UserValidateMethodEnum.phone:
        options.mobileVerifyCode = values?.verifyCode
        break
      case UserValidateMethodEnum.validator:
        options.googleVerifyCode = values?.verifyCode
        break
      case UserValidateMethodEnum.pwd:
        options.withdrawalPwd = values?.verifyCode
        break
      default:
        break
    }
    const res = await postMemberUniversalSecurityVerification(options)
    if (res.data?.isSuccess) {
      onSuccess(true, options)
      handleClose()
      return
    }
    onSuccess(false)
    onError && onError()
  }

  const { run } = useRequest(handleValidateCode, {
    throttleWait: 2000,
    manual: true,
  })

  const onSubmit = async values => {
    // setLoading(true)
    run(values)
  }

  const handleClose = () => {
    form.resetFields()
    onClose && onClose(false)
  }

  return (
    <UserPopUp
      title={<div style={{ textAlign: 'left' }}>{t`features_user_withdraw_safety_verification_index_47d1ah2quy`}</div>}
      autoFocus={false}
      className="user-popup withdraw-user-popup"
      maskClosable={false}
      visible={isShow}
      closeIcon={<Icon name="close" />}
      onCancel={handleClose}
      footer={null}
    >
      <div className={`universal-security-verification user-form-style ${styles.scoped}`}>
        <div className="universal-security-verification-wrap">
          <Form
            form={form}
            layout="vertical"
            onSubmit={onSubmit}
            autoComplete="off"
            validateTrigger="onBlur"
            className={'widthdraw-safety'}
            // onChange={handleValidateChange}
          >
            {memberUserInfo?.isEmailEnable &&
              memberUserInfo?.verifyInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable &&
              verifyMethod === UserValidateMethodEnum.email && (
                <FormItem
                  field="verifyCode"
                  requiredSymbol={false}
                  rules={[rules.emailCode]}
                  extra={t({
                    id: 'user.universal_security_verification_01',
                    values: { 0: UserInformationDesensitization(userInfo?.email) },
                  })}
                >
                  <Input
                    type="number"
                    maxLength={6}
                    placeholder={t`user.field.reuse_20`}
                    suffix={<UserCountDown onSend={handleSendEmailValidateCode} />}
                  />
                </FormItem>
              )}

            {memberUserInfo?.isMobileEnable &&
              memberUserInfo?.verifyInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable &&
              verifyMethod === UserValidateMethodEnum.phone && (
                <FormItem
                  field="verifyCode"
                  requiredSymbol={false}
                  rules={[rules.phoneCode]}
                  extra={t({
                    id: 'user.universal_security_verification_03',
                    values: {
                      0: `+${userInfo?.mobileCountryCd} ${UserInformationDesensitization(userInfo?.mobileNumber)}`,
                    },
                  })}
                >
                  <Input
                    type="number"
                    maxLength={6}
                    placeholder={t`user.field.reuse_21`}
                    suffix={<UserCountDown onSend={handleSendPhoneValidateCode} />}
                  />
                </FormItem>
              )}

            {memberUserInfo?.isGoogleEnable &&
              memberUserInfo?.verifyInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable &&
              verifyMethod === UserValidateMethodEnum.validator && (
                <FormItem
                  field="verifyCode"
                  requiredSymbol={false}
                  rules={[rules.googleKey]}
                  extra={t`user.safety_verification_06`}
                >
                  <Input
                    type="number"
                    maxLength={6}
                    placeholder={t`user.safety_verification_05`}
                    suffix={<div></div>}
                  />
                </FormItem>
              )}

            {memberUserInfo?.isWithdrawPwdEnable &&
              memberUserInfo?.verifyInfo.isOpenWithdrawalPwd === UserEnabledStateTypeEnum.enable &&
              verifyMethod === UserValidateMethodEnum.pwd && (
                <FormItem
                  field="verifyCode"
                  requiredSymbol={false}
                  rules={[rules.coinWithdrawal]}
                  extra={t`features_user_withdraw_safety_verification_index_drwib1rzin`}
                >
                  <Input
                    type={passwordShow ? 'number' : 'password'}
                    maxLength={6}
                    placeholder={t`features_user_safety_items_application_form_index_qcbbqxsgmh`}
                    suffix={
                      <Icon
                        onClick={() => setPasswordShow(!passwordShow)}
                        name={passwordShow ? 'eyes_open' : 'eyes_close'}
                      />
                    }
                  />
                </FormItem>
              )}

            <div className="verify-mode">
              {memberUserInfo?.isEmailEnable &&
                memberUserInfo?.verifyInfo?.isOpenEmailVerify === UserEnabledStateTypeEnum.enable &&
                verifyMethod !== UserValidateMethodEnum.email && (
                  <div className="item">
                    <label
                      onClick={() => switchVerifyMethod(UserValidateMethodEnum.email)}
                    >{t`user.safety_verification_09`}</label>
                  </div>
                )}

              {memberUserInfo?.isGoogleEnable &&
                memberUserInfo?.verifyInfo?.isOpenGoogleVerify === UserEnabledStateTypeEnum.enable &&
                verifyMethod !== UserValidateMethodEnum.validator && (
                  <div className="item">
                    <label
                      onClick={() => switchVerifyMethod(UserValidateMethodEnum.validator)}
                    >{t`user.safety_verification_10`}</label>
                  </div>
                )}

              {memberUserInfo?.isMobileEnable &&
                memberUserInfo?.verifyInfo?.isOpenPhoneVerify === UserEnabledStateTypeEnum.enable &&
                verifyMethod !== UserValidateMethodEnum.phone && (
                  <div className="item">
                    <label
                      onClick={() => switchVerifyMethod(UserValidateMethodEnum.phone)}
                    >{t`user.safety_verification_11`}</label>
                  </div>
                )}

              {memberUserInfo?.isWithdrawPwdEnable &&
                memberUserInfo?.verifyInfo?.isOpenWithdrawalPwd === UserEnabledStateTypeEnum.enable &&
                verifyMethod !== UserValidateMethodEnum.pwd && (
                  <div className="item">
                    <label
                      onClick={() => switchVerifyMethod(UserValidateMethodEnum.pwd)}
                    >{t`features_user_withdraw_safety_verification_index_uje0xtczj2`}</label>
                  </div>
                )}

              <div className="item">
                <label onClick={handleToSecurityItem}>{t`user.safety_verification_12`}</label>
              </div>
            </div>

            <FormItem style={{ marginBottom: 0 }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                // disabled={disabled || !verifyCode}
              >
                {t`user.field.reuse_02`}
              </Button>
            </FormItem>
          </Form>
        </div>

        <UserPopUp
          className="user-popup user-popup-tips"
          visible={workOrderPopUp}
          closeIcon={<Icon name="close" />}
          okText={t`user.safety_verification_14`}
          cancelText={t`user.field.reuse_48`}
          onOk={() => {
            link(customerServiceLinks.toString())
            setWorkOrderPopUp(false)
          }}
          onCancel={() => setWorkOrderPopUp(false)}
        >
          <UserPopupTipsContent slotContent={<p>{t`user.safety_verification_13`}</p>} />
        </UserPopUp>

        <FullScreenSpin isShow={spinShow} />
      </div>
    </UserPopUp>
  )
}

export default UserSafetyVerification

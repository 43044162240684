import Icon from '@/components/icon'
import { link } from '@/helper/link'
import { t } from '@lingui/macro'
import UserPopUp from '@/features/user/components/popup'
import UserPopupTipsContent from '@/features/user/components/popup/content/tips'
import useMemberBaseSafaVerify from '@/hooks/features/member-verify'
import styles from './index.module.css'

interface securityPopup {
  /** 弹框显示 */
  isShow?: boolean
  /** 关闭弹框 */
  setIsShow(val): void
  /** 关闭其他的弹窗 */
  setOtherClose?: () => void
  /** 提币按钮 */
  isWithdraw?: boolean
}

function OpenSecurityPopup(props: securityPopup) {
  const { isShow, setIsShow, setOtherClose, isWithdraw = false } = props
  const memberBaseSafaVerify = useMemberBaseSafaVerify()
  // 动态生成字母序列
  const alphabet = 'ABCD'
  let index = -1

  /** 去开启两项验证 */
  const onOpenSecurity = () => {
    isShow && setIsShow(false)
    link('/personal-center/account-security')
    setOtherClose && setOtherClose()
  }
  /** 关闭弹框 */
  const handleClose = () => {
    isShow && setIsShow(false)
  }

  return (
    <UserPopUp
      className="user-popup user-popup-tips popup-width"
      visible={isShow}
      closeIcon={<Icon name="close" hasTheme />}
      okText={t`user.universal_security_verification_08`}
      cancelText={t`user.field.reuse_44`}
      onOk={onOpenSecurity}
      onCancel={handleClose}
    >
      <UserPopupTipsContent
        showIcon={false}
        className={styles.scoped}
        slotContent={
          <>
            <p className="popup-title">{t`features/c2c-trade/advertisement-manager/index-11`}</p>
            {isWithdraw ? (
              <p className="popup-small-title">{t`features_assets_common_open_security_popup_index_w2aluwjf4p`}</p>
            ) : (
              <p className="popup-small-title">{t`user.universal_security_verification_07`}</p>
            )}
            {memberBaseSafaVerify?.isEmailEnable && (
              <p className="popup-small-title">
                {alphabet[(index += 1)]}
                {t`features_assets_common_open_security_popup_index_nl7hrtfkhh`}
              </p>
            )}
            {memberBaseSafaVerify?.isMobileEnable && (
              <p className="popup-small-title">
                {alphabet[(index += 1)]}
                {t`features_assets_common_open_security_popup_index_riwwbiexgn`}
              </p>
            )}
            {memberBaseSafaVerify?.isGoogleEnable && (
              <p className="popup-small-title">
                {alphabet[(index += 1)]}
                {t`features_assets_common_open_security_popup_index_gqibbx4khg`}
              </p>
            )}
            {memberBaseSafaVerify?.isWithdrawPwdEnable && (
              <p className="popup-small-title">
                {alphabet[(index += 1)]}
                {t`features_assets_common_open_security_popup_index_qkwclgmawd`}
              </p>
            )}
          </>
        }
      />
    </UserPopUp>
  )
}

export default OpenSecurityPopup
